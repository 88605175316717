<template>
  <div ref="modal" class="info-window-bg" @keydown.esc="$emit('windowClose')" tabindex="-1">
    <div class="info-window">
      <div class="info-window-close-button" @click="$emit('windowClose')">x</div>
      <div class="info-window-content">
        <slot>Info window content.</slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoWindow",
  mounted() {
    this.$refs.modal.focus();
  }
}
</script>

<style lang="scss" scoped>
  .info-window-bg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    float: top;
    background-color: rgba(0,0,0,0.6);

    .info-window {
      text-align: left;
      margin-left: auto;
      margin-right: auto;
      margin-top: 40px;
      padding: 10px;
      border-radius: 10px;
      width: clamp(300px, 90vw, 750px);
      background-color: white;
      max-height: calc(100vh - 100px);
      overflow-y: auto;
      font-size: clamp(9pt, 12pt, 16pt);

      .info-window-close-button {
        float: right;
        border: solid 1px #E2E2E2;
        border-radius: 30px;
        padding: 4px 9px;
        font-weight: bold;
        color: #B2B2B2;
        box-shadow: 1px 1px 0px rgba(0,0,0,0.1);
        cursor: pointer;
        &:hover {
          color: #ff9757;
          border-color: #ffcaab;
        }
      }

      .info-window-content {
        padding: 20px;
      }
    }
  }
</style>