<template>
  <div class="main-column">
    <div class="navbar">
      <div class="logo">
        <img alt="Leffoissa.net logo" src="./assets/leffoissa_logo_beta.png">
      </div>
      <div class="search-box" @keydown.esc="searchFieldEscPressed($event)">
        <close-icon class="hide-me"/><input name="search" v-model="searchQuery" placeholder="Hae elokuvan nimellä..."><br/>
        <div class="age-select">
          <div class="age-selection-list" title="Valitse suurin sallittu ikäraja">
            <div class="age-selection-item rating-s" v-if="ratingPickerOpen || chosenRating == 'S'" @click="ratingChosen('S')">S</div><div class="age-selection-item rating-7" v-if="ratingPickerOpen || chosenRating == '7'" @click="ratingChosen('7')">7</div><div class="age-selection-item rating-12" v-if="ratingPickerOpen || chosenRating == '12'" @click="ratingChosen('12')">12</div><div class="age-selection-item rating-16" v-if="ratingPickerOpen || chosenRating == '16'" @click="ratingChosen('16')">16</div><div class="age-selection-item rating--" v-if="ratingPickerOpen || chosenRating == '-'" @click="ratingChosen('-')">-</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-area">
      <div class="info-button" @click="openInfoWindow" placeholder="Tietoa sivusta"></div>
      <div class="last-updated">Päivitetty: {{ getHumanDate(movieList.lastUpdated) }}</div>
      <nav>
        <div class="nav-item">
          leffoissa <span class="nav-highlight">NYT</span><br/>
          <div class="nav-underline"><img src="./assets/heading_underline.svg"/></div>
        </div>
      </nav>
      <div class="main-content">
        <ul class="movie-list-items">
          <li v-for="movie in filteredMovieList" :key="movie.id">
            <MovieListItem :movie="movie" :selectedTags="selectedTags" @toggleTag="toggleTag" @ratingChosen="ratingChosen" @startTrailer="startTrailer" />
          </li>
        </ul>
        <div class="movie-info-sources">
          Lähteet:
          <a href="http://www.themoviedb.org/" target="_tmdb">themoviedb.org</a>,
          <a href="http://www.finnkino.fi/" target="_finnkino">Finnkino</a>
        </div>
      </div>
    </div>
  </div>
  <InfoWindow @windowClose="closeInfoWindow()" v-if="infoWindowOpen">
    <h2>Leffoissa?</h2>
    <p>
      1) Mitä elokuvia esitetään tällä hetkellä leffoissa?<br/>
      2) Mitkä näistä voisivat olla katsomisen arvoisia?<br/>
      <br/>
      Nämä kaksi kysymystä tulee ensimmäisenä mieleen kun alkaa tehdä mieli ison valkokankaan ja hyvän elokuvan
      luomaa tunnelmaa. Ensimmäiseen löytää melko helposti vastauksen, mutta toiseen kysymykseen vastauksen löytäminen
      on aina ollut hankalaa. Näin ollen päätin koota luettelon elokuvateattereissa esitettävistä elokuvista sekä näiden yleisarvioista.
      Ja kun kerran luettelo on koottu, niin miksipä sitä ei jakaisi muidenkin kanssa. Näin syntyi leffoissa.net.
      <br/><br/>
      Lisäämällä sivun kirjainmerkkeihisi voit aina helposti pysyä kärryillä uusista katsomisen arvoisista elokuvista.
    </p>
    <h2>Mistä arviot tulevat?</h2>
    <p>
      Kerään tietoa eri Internet-lähteistä ja tästä muodostuneen käsityksen mukaan annan leffalle tähdet.
    </p>
    <h2>Mistä voin tietää, että arviot vastaavat omaa elokuvamakuani?</h2>
    <p>
      No siinäpä se. Et voi sitä millään varmaksi tietää. Tähdet vastaavat kuitenkin pääsääntöisesti yleistä
      mielipidettä, joten aidosti hyvän elokuvan löytäminen luettelon yläpäästä on ainakin todennäköisempää
      kuin pelkkien elokuvaesittelyiden perusteella valitessa.
    </p>
    <h2>Ovatko elokuvat tietyssä järjestyksessä?</h2>
    <p>
      Kyllä. Parhaat arvostelut saaneet elokuvat ovat listan kärjessä ja tähdet vähenevät loppua kohti.
    </p>
    <h2>Voinko antaa palautetta?</h2>
    <p>
      Ilman muuta ja mielellään! Lähetä kysymykset, ideat ja palautteet osoitteeseen <a
        href="mailto:info@leffoissa.net">info@leffoissa.net</a>
    </p>
  </InfoWindow>
  <ModalVideo channel="youtube" :videoId="videoId" v-model:isOpen="videoWindowOpen" />
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
</template>

<script>
import movies from '@/assets/movie-list.json'
import MovieListItem from "@/components/MoviewListItem";
import InfoWindow from "@/components/InfoWindow";
import moment from 'moment';
import ModalVideo from "@/components/ModalVideo";
import CloseIcon from 'vue-material-design-icons/Close.vue';

export default {
  name: 'App',
  components: {
    InfoWindow,
    MovieListItem,
    ModalVideo,
    CloseIcon
  },
  data() {
    return {
      movieList: movies,
      infoWindowOpen: false,
      videoWindowOpen: false,
      currentYoutubeVideoID: "",
      ratingPickerOpen: false,
      chosenRating: '-',
      searchQuery: '',
      selectedTags: [],
      windowWidth: window.innerWidth
    }
  },
  methods: {
    linkName: function (str) {
      var a = {"å":"a","ä":"a","ö":"o","Å":"A","Ä":"A","Ö":"O"};
      str = str.split('').map(function (char) {
        return a[char] || char;
      }).join("");
      return str.trim().toLocaleLowerCase().replace().replace(/[^a-z0-9 ]/g, "").replace(/\s\s/g, " ").replace(/\s/g, "-");
    },
    getHumanDate: function (date) {
      return moment(date).format('D.M.YYYY')
    },
    openInfoWindow() {
      document.body.classList.add("modal-open")
      this.infoWindowOpen = true
      this.ratingPickerOpen = false
    },
    closeInfoWindow() {
      document.body.classList.remove("modal-open")
      this.infoWindowOpen = false
    },
    toggleTag: function (tagName) {
      if (!this.selectedTags.includes(tagName)) {
        this.selectedTags.push(tagName)
      } else {
        this.selectedTags.splice(this.selectedTags.indexOf(tagName), 1)
      }
    },
    ratingChosen: function (rating, openCloseMenu=true) {
      if (openCloseMenu) {
        this.chosenRating = rating
        this.ratingPickerOpen = !this.ratingPickerOpen
      }
      else {
        if (this.chosenRating == rating) {
          this.chosenRating = '-'
        }
        else {
          this.chosenRating = rating
        }
      }
    },
    startTrailer: function (trailerId) {
      this.currentYoutubeVideoID = trailerId
      this.videoWindowOpen = true
    },
    searchFieldEscPressed: function (event) {
      this.searchQuery = ''
      event.target.blur()
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  computed: {
    videoId() {
      return this.currentYoutubeVideoID
    },
    filteredMovieList() {
      return this.movieList.movieList.filter(function (movie) {
        if (movie.title.toLowerCase().indexOf(this.searchQuery.toLowerCase()) === -1) {
          return false
        }
        for (let i = 0; i < this.selectedTags.length; i++) {
          if (!movie.genres.find(genre => {
            return genre.name == this.selectedTags[i]
          })) {
            return false
          }
        }
        if (this.chosenRating != '-') {
          switch (this.chosenRating) {
            case 'S':
              if (movie.rating != 'S') {
                return false;
              }
              break
            case '7':
              if (!['S', '7'].includes(movie.rating)) {
                return false;
              }
              break
            case '12':
              if (!['S', '7', '12'].includes(movie.rating)) {
                return false;
              }
              break
            case '16':
              if (!['S', '7', '12', '16'].includes(movie.rating)) {
                return false;
              }
              break
          }
        }
        return true
      }.bind(this))
    }
  }
}
</script>

<style lang="scss">
html {
  font-size: 62.5%;
}
.hide-me {
  visibility: hidden;
}
#app {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-image: url("assets/filmstrip_bg.png");
  background-repeat: repeat;

  a {
    text-decoration: none;
    color: #7B7B7B;
  }

  a:hover {
    color: #E7742E;
  }

  .main-column {
    max-width: 1100px;
    padding: 35px;
    @media only screen and (max-width: 600px) {
      padding: 0px;
    }
    text-align: left;
    overflow: auto;

    .navbar {
      padding-bottom: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .logo {
        @media only screen and (max-width: 600px) {
          padding: 10px;
          IMG {
            width: 160px;
          }
        }
      }

      .search-box {
        flex-grow: 1;
        text-align: right;
        line-height: 30px;
        font-size: 1.2rem;
        letter-spacing: normal;

        .age-select {
          position: relative;

          .age-selection-list {
            @media only screen and (max-width: 600px) {
              margin-right: 10px;
            }
            margin-top: 4px;
            position: absolute;
            display: flex;
            right: 0px;
            top: 0px;

            .age-selection-item {
              line-height: 24px;
              background-color: #9D9D9D;
              border: solid 1px #9D9D9D;
              border-radius: 30px;
              font-size: 1.6rem;
              font-weight: bold;
              margin-left: 5px;
              color: #B2B2B2;
              cursor: pointer;
              width: 25px;
              height: 25px;
              text-align: center;
              padding: 2px 1px 0px 1px;
              cursor: pointer;
              &:hover {
                border-color: #ffcaab;
              }
              &.rating-7 {
                color: #fffba2;
              }
              &.rating-12,&.rating-16 {
                color: #ffd758;
              }
              &.rating-18 {
                color: #ffc8be;
              }
              &.rating-s {
                color: #c6e8a4;
              }
              &.rating-- {
                color: #E2E2E2;
              }
            }
          }

        }

        input {
          border: 1px solid #c9c9c9;
          font-size: 1.2rem;
          padding: 5px;
          border-radius: 5px;
          width: clamp(140px, 20vw, 250px);
          position: relative;
          @media only screen and (max-width: 600px) {
            margin-right: 10px;
          }
        }
      }
    }

    .content-area {
      background-color: white;
      border-radius: 10px;
      box-shadow: 0px 2px 8px #B2B2B2;

      .last-updated {
        color: #D0D0D0;
        font-size: 1rem;
        padding-top: 7px;
        padding-left: 7px;
      }

      .info-button {
        background-image: url("assets/button_info.png");
        width: 19px;
        height: 18px;
        float: right;
        margin: 7px;

        &:hover {
          transform: scale(1.02, 1.02);
          background-image: url("assets/button_info_active.png");
          cursor: pointer;
        }
      }
      nav {
        width: auto;
        display: flex;
        margin: 0 auto;
        justify-content: center;
        font-size: clamp(2rem, 4vw, 4rem);
        font-weight: 700;
        letter-spacing: -.17rem;
        padding-bottom: clamp(5pt, 2vw, 15pt);

        .nav-item {
          padding-top: 1rem;

          .nav-underline {
            position: relative;
            height: 8px;
            max-height: 8px;

            IMG {
              position: absolute;
              min-width: 100%;
              width: 1.2rem;
              margin-top: 0;
              filter: invert(74%) sepia(0%) saturate(10%) hue-rotate(251deg) brightness(95%) contrast(90%);
            }
          }
        }

        .nav-highlight {
          color: #E7742E;
        }
      }

      .main-content {
        padding: 0 18px;
        @media only screen and (max-width: 600px) {
          padding: 0px;
        }

        ul {
          padding: 0;
          margin: 0;
          list-style-type: none;

          li:nth-of-type(2n+1) {
            background: linear-gradient(180deg, rgba(220, 220, 220, 0.1) 0%, rgba(220, 220, 220, 0.4) 100%);
          }

          li:last-of-type {
            border-bottom: solid 3px;
            margin-bottom: 5px;
            border-color: #E2E2E2;
          }
        }

        .movie-info-sources {
          color: #D0D0D0;
          font-size: 1.2rem;
          margin-bottom: 4px;
          @media only screen and (max-width: 600px) {
            margin-right: 10px;
            margin-bottom: 0.6em;
          }
          display: flex;
          justify-content: right;
          padding-bottom: 20px;

          a {
            color: #D0D0D0;
          }

          a:hover {
            color: #E7742E;
          }
        }

      }
    }
  }
}
</style>
