<template>
  <a :name='$parent.linkName(movie.title)'></a>
  <div class="movie-item" @click="toggleDescription(movie.id)">
    <div class="movie-graphic" v-if="movie.id"><img :src="require(`@/assets/posters/mini/${movie.id}.jpg`)" alt="Elokuvan julistekuva"/></div>
    <div class="movie-core-content">
      <div class="movie-title">{{ movie.title }}</div>
      <div class="movie-links">
        <div class="movie-link"><a :href="finnkinoLink" target="_blank" v-on:click.stop><img src="../assets/icon_finnkino.png" alt="Finnkino-ikoni"/> Elokuvan tiedot Finnkino.fi:ssä</a></div>
        <div v-if="imdbLink" class="movie-link"><a :href="imdbLink" target="_blank" v-on:click.stop><img src="../assets/icon_imdb.png" alt="IMDB-ikoni"/> Elokuvan tiedot IMDB:ssä</a></div>
        <div v-if="movie.trailerId" class="movie-link" @click="$emit('startTrailer', movie.trailerId)" v-on:click.stop><a><img src="../assets/icon_trailer.png" alt="Traileri-ikoni"/> Traileri</a></div>
      </div>
      <div class="movie-actor-row">
        <div class="movie-actor">{{ (movie.actors && movie.actors.length > 0) ? movie.actors[0].name : '' }}</div>
        <div class="movie-actor">{{ (movie.actors && movie.actors.length > 1) ? movie.actors[1].name : '' }}</div>
        <div class="movie-actor">{{ movie.director ? movie.director.name : '' }}</div>
      </div>
      <div class="movie-actor-row">
        <div class="movie-actor">{{ (movie.actors && movie.actors.length > 2) ? movie.actors[2].name : '' }}</div>
        <div class="movie-actor">{{ (movie.actors && movie.actors.length > 3) ? movie.actors[3].name : '' }}</div>
      </div>
      <div class="movie-description" v-if="movie.description">
          {{ descriptionEnabledForMovieId === movie.id ? movie.description : shortenSentence(movie.description, 120) }}
      </div>
    </div>
    <div class="movie-right-column">
      <div class="star-rating">
        <img v-if="numStars >= 1" src="../assets/icon_star.png" alt="Tähti"/>
        <img v-if="numStars >= 2" src="../assets/icon_star.png" alt="Tähti"/>
        <img v-if="numStars >= 3" src="../assets/icon_star.png" alt="Tähti"/>
        <img v-if="numStars >= 4" src="../assets/icon_star.png" alt="Tähti"/>
        <img v-if="numStars === 5" src="../assets/icon_star.png" alt="Tähti"/>
        <img v-if="typeof numStars === 'number' && numStars % 1 != 0" class="half" src="../assets/icon_star_half.png"/>
      </div>
      <div class="movie-categories">
        <div v-if="ratingClasses.includes(movie.rating)" :class="['age-rating', 'rating-' + movie.rating]" @click="$emit('ratingChosen', movie.rating, false)" title="Suodata suurimman sallitun ikärajan mukaan">{{ movie.rating }}</div>
        <div v-for="genre in movie.genres" :key="genre.id" class="movie-category" :class="activeTag(genre.name) ? 'selected' : ''" @click="$emit('toggleTag', genre.name)" v-on:click.stop title="Suodata genren mukaan">{{ genre.name }}</div>
      </div>
      <div class="movie-details"><span v-if="movie.runtime">{{ formatRuntime(movie.runtime) }}, </span>{{ language }}</div>
      <div class="weeks-listed" v-if="movie.premiereDate">
        <span v-if="weeksSince(movie.premiereDate) == -1">Ensi-ilta myöhemmin tällä viikolla</span><span v-else>Ollut ohjelmistossa {{ weeksSince(movie.premiereDate) }} {{ weeksSince(movie.premiereDate) != 1 ? 'viikkoa' : 'viikon' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import languages from '@/assets/languages.json'
export default {
  name: 'MovieListItem',
  props: {
    movie: Object,
    selectedTags: Array
  },
  emits: ['toggleTag', 'ratingChosen', 'startTrailer'],
  data() {
    return {
      ratingClasses: [ 'S', '7', '12', '16', '18' ],
      descriptionEnabledForMovieId: null,
      languages: languages
    }
  },
  methods: {
    weeksSince: function (date) {
      let x = moment()
      let y = moment(date)
      return Math.floor(moment.duration(x.diff(y)).asWeeks())
    },
    activeTag(tagName) {
      return this.selectedTags.includes(tagName)
    },
    formatRuntime(runtime) {
      let h = Math.floor(runtime / 60)
      let min = runtime % 60;
      return ((h) ? h + 't ' : '') + min + ' min'
    },
    toggleDescription(movieId) {
      this.descriptionEnabledForMovieId = this.descriptionEnabledForMovieId == null ? movieId : null;
    },
    enableDescription(movieId) {
      this.descriptionEnabledForMovieId = movieId;
    },
    disableDescriptions() {
      this.descriptionEnabledForMovieId = null;
    },
    shortenSentence(theString, maxLength) {
      // trim the string to the maximum length
      var trimmedString = theString.substr(0, maxLength);

      // re-trim if we are in the middle of a word
      trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
      return trimmedString + '...'
    }
  },
  computed: {
    numStars() {
      return this.movie.stars
    },
    language() {
      if (this.movie.language in this.languages) {
        return this.languages[this.movie.language]
      }
      return this.movie.language
    },
    finnkinoLink() {
      return 'https://www.finnkino.fi/event/' + this.movie.id
    },
    imdbLink() {
      return (this.movie.imdbId ? 'https://www.imdb.com/title/' + this.movie.imdbId : '')
    }
  }
}
</script>

<style lang="scss" scoped>

.movie-item {
  display: flex;
  flex-direction: row;
  border-top: solid 3px;
  border-color: #E2E2E2;
  padding: 10px;

  .movie-graphic {
    width: 92px;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    background-image: url("../assets/img_placeholder.svg");
    background-repeat: no-repeat;
    background-size: 150px;
  }
  .movie-core-content {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    .movie-title {
      font-size: clamp(12pt, 4vw, 26pt);
      font-weight: 600;
      letter-spacing: -1pt;
    }
    .movie-links {
      display: flex;
      flex-direction: row;
      margin: 5px 0 10px 0;
      font-size: 1.4em;
      align-items: center;
      @media only screen and (max-width: 600px) {
        font-size: 1.2em;
      }
      .movie-link {
        margin-right: 10px;
        cursor: pointer;
      }
      img {
        vertical-align: middle;
      }
    }
    .movie-actor-row {
      display: flex;
      flex-direction: row;
      .movie-actor {
        width: 45%;
        font-size: 1.6em;
        line-height: 24px;
        @media only screen and (max-width: 600px) {
          font-size: 1.2em;
        }
      }
    }
    .movie-description {
      margin-top: 20px;
      max-width: 480px;
      font-size: 1.6rem;
      line-height: 2.2rem;
      padding-right: 5px;
      letter-spacing: 0.002rem;
      @media only screen and (max-width: 600px) {
        font-size: 1.35rem;
        line-height: 1.65rem;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .movie-right-column {
    flex-grow: 1;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: right;
    .star-rating {
      white-space: nowrap;
      IMG {
        height: clamp(16px, 4vw, 33px);
      }
      flex-shrink: 0;
      padding-top: 4px;
      margin-bottom: 10px;
    }
    .movie-categories {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-content: end;
      margin-left: auto;
      .age-rating {
        line-height: 20px;
        background-color: #adadad;
        border: solid 1px #9D9D9D;
        border-radius: 26px;
        font-size: 16px;
        font-weight: bold;
        color: #B2B2B2;
        cursor: pointer;
        width: 20px;
        height: 20px;
        text-align: center;
        padding: 2px 2px 1px 1px;
        position: relative;
        top: -2px;
        cursor: pointer;
        &.rating-7 {
          color: #fffba2;
        }
        &.rating-12,&.rating-16 {
          color: #ffd758;
        }
        &.rating-18 {
          color: #ffc8be;
        }
        &.rating-S {
          color: #c6e8a4;
        }
      }
      .movie-category {
        color: white;
        font-weight: 700;
        letter-spacing: 0.1pt;
        background-color: #adadad;
        border-radius: 4px;
        padding: 3px 6px;
        font-size: 1.2em;
        margin-left: 6px;
        margin-bottom: 6px;
        &:hover {
          background-color: #c4c4c4;
          cursor: pointer;
        }
        &.selected {
          background-color: #ff7e31;
          &:hover {
            background-color: #ff9757;
          }
        }
      }
    }
    .movie-details {
      margin-top: 5px;
      margin-bottom: auto;
      font-size: 11px;
      color: #adadad;
    }
    .weeks-listed {
      margin-top: auto;
      font-size: 14px;
      @media only screen and (max-width: 600px) {
        font-size: 1.2em;
        line-height: 1.2em;
      }
    }
  }
}
</style>
